import {
    Jumbotron,
    JumbotronProps
} from 'common/components/organisms/Jumbotron'
import { Layout, LayoutProps } from 'common/components/organisms/Layout'
import React from 'react'

export interface ErrorProps {
    jumbotron: JumbotronProps
    layout: LayoutProps
}

export const Error: React.FC<ErrorProps> = ({ jumbotron, layout }) => {
    return (
        <Layout {...layout} box={{ bgcolor: '#fff' }}>
            <Jumbotron {...jumbotron} />
        </Layout>
    )
}
