import { getJumbotronData } from 'common/components/organisms/Jumbotron'
import { Error } from 'common/components/templates/Error'
import { getLayoutData } from 'common/utils/getLayoutData'
import React from 'react'

export interface ErrorPageProps {
    pageContext: any
}

export const ErrorPage: React.FC<ErrorPageProps> = ({ pageContext }) => {
    return <Error {...getErrorData(pageContext)} />
}

export default ErrorPage

export const getErrorData = (data: any) => ({
    layout: getLayoutData(data),
    jumbotron: getJumbotronData(data)
})
